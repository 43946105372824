import React, { useEffect, useState } from 'react';
import { Box, HStack, Text, Badge, Wrap, WrapItem } from '@chakra-ui/react';
import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';

const SkillsAnalysis = ({ questions, questionsHash, submissionsResponses }) => {
  const { t } = useTranslation();
  const [skillsPerformance, setSkillsPerformance] = useState({ top: [], bottom: [] });

  useEffect(() => {
    if (questions.length > 0 && Object.keys(submissionsResponses).length > 0) {
      const skillStats = {};
      
      questions.forEach((question) => {
        if (!question.skills) return;
        
        question.skills.forEach((skill) => {
          skillStats[skill] = skillStats[skill] || {
            correct: 0,
            total: 0,
            name: skill
          };
          
          Object.values(submissionsResponses).forEach((response) => {
            if (response[question.id]) {
              skillStats[skill].total++;
              if (questionsHash[question.id][response[question.id].id]) {
                skillStats[skill].correct++;
              }
            }
          });
        });
      });

      const skillsArray = Object.values(skillStats)
        .filter(stat => stat.total > 0)
        .map(stat => ({
          ...stat,
          percentage: Math.round((stat.correct / stat.total) * 100)
        }));

      if (skillsArray.length <= 2) {
        setSkillsPerformance({ top: [], bottom: [] });
        return;
      }

      const sortedSkills = [...skillsArray].sort((a, b) => b.percentage - a.percentage);
      
      setSkillsPerformance({
        top: sortedSkills.slice(0, 3),
        bottom: sortedSkills.slice(-3).reverse()
      });
    }
  }, [questions, submissionsResponses, questionsHash]);

  if (!skillsPerformance.top.length) return null;

  return (
    <Box mb={4}>
      <Wrap spacing={4}>
        <WrapItem flexBasis="calc(50% - 8px)" flexGrow={1}>
          <Box w="100%" bg="green.50" p={2} borderRadius="md">
            <Text fontSize="sm" fontWeight="bold" mb={2} color="green.700">
              {t('skills.topPerforming')}
            </Text>
            {skillsPerformance.top.map((skill) => (
              <HStack key={skill.name} mb={1} fontSize="sm">
                <CheckCircleIcon color="green.500" boxSize={3} />
                <Text flex="1" noOfLines={1}>{skill.name}</Text>
                <Badge colorScheme="green" fontSize="xs">
                  {skill.percentage}% ({skill.correct}/{skill.total})
                </Badge>
              </HStack>
            ))}
          </Box>
        </WrapItem>

        <WrapItem flexBasis="calc(50% - 8px)" flexGrow={1}>
          <Box w="100%" bg="red.50" p={2} borderRadius="md">
            <Text fontSize="sm" fontWeight="bold" mb={2} color="red.700">
              {t('skills.needsImprovement')}
            </Text>
            {skillsPerformance.bottom.map((skill) => (
              <HStack key={skill.name} mb={1} fontSize="sm">
                <WarningIcon color="red.500" boxSize={3} />
                <Text flex="1" noOfLines={1}>{skill.name}</Text>
                <Badge colorScheme="red" fontSize="xs">
                  {skill.percentage}% ({skill.correct}/{skill.total})
                </Badge>
              </HStack>
            ))}
          </Box>
        </WrapItem>
      </Wrap>
    </Box>
  );
};

export default SkillsAnalysis; 